import request from '@/until/request.js'
const prefixName = 'order/webPay/meal'

// 获取账户列表
export function getMealList(){
  return request({
    url: 'order/webPay/meal',
    method: 'get'
  })
}
export function createOrder(result){
  return request({
    url: 'order/webPay',
    method: 'post',
    data: result
  })
}
export function getPayStatus(trxId){
    return request({
        url: 'order/webPay/count',
        method: 'get',
        params: {trxId}
    })
}
export function getDueDate(){
    return request({
        url: 'base/user-custom/dueDate',
        method: 'get'
    })
}
export function DeleteAccount(id){
    return request({
        url: prefixName + '/' + id,
        method: 'delete'
    })
}
export function SettingInitial(result){
  return request({
    url: '/yny/webInitial/aut',
    method: 'post',
    data: result
  })
}